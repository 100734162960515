.trips-filter{
    margin: 0 0 10px;
}
.trips {
    cursor: pointer;

    ion-item {
        > div{
            padding: 10px 0;
        }
        &:hover {
            --background: #B8D8C7;

            .price {
                color: black;
            }
        }
    }

    h4,
    h5 {
        margin: 0;
    }


    h4,h5,.price {
        font-weight: 600;
    }

    ion-text {
        line-height: 1.5;
    }
}