section.h-products{
    .title{
        font-family: var(--primary-font-family);
        font-weight: 700;
        font-size: 30px;
        margin-left: 10px;
        hr{
            background: var(--ion-color-primary);
            width: 60px;
            height: 3px;
            margin: 10px 0;
        }
    }
    .swiper {
        padding: 0 20px;
    }
}
