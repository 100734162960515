ion-item.product {
    height: 100px;
    cursor: pointer;

    .thumb-containter {
        margin: 0 10px 0 0;
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;

        ion-button {
            position: absolute;
            background: var(--ion-color-light);
            transition: .5s bottom;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -50px;
            width: max-content;
        }
    }

    .pricing {
        width: 55px;
        padding: 0;
        margin: 0 0 0 10px;
        text-align: right;
        .regular {
            text-decoration: line-through;
        }

        .sale {
            font-size: 16px;
            font-weight: 700;
            margin-left: 5px;
        }
    }

    .name {
        width: 500px;
    }

    &:hover {
        .thumb-containter {
            img {
                filter: blur(8px);
            }

            ion-button {
                bottom: 35%;
            }
        }
    }
}
