/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

// Reference Template https://woodmart.xtemos.com/handmade/
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Lora&display=swap');

/** Ionic CSS Variables **/
:root {

    --primary-font-family: "Karla", Arial, Helvetica, sans-serif;
    --secondary-font-family: "Lora", Arial, Helvetica, sans-serif;

    /** primary **/
    --ion-color-primary: #122C66;
    --ion-color-primary-rgb: 121, 179, 138;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #1E407B;
    --ion-color-primary-tint: #122C66;

    /** secondary **/
    --ion-color-secondary: #c1cdc4;
    --ion-color-secondary-rgb: 193, 205, 196;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #aab4ac;
    --ion-color-secondary-tint: #c7d2ca;

    /** tertiary **/
    --ion-color-tertiary: #52d4ff;
    --ion-color-tertiary-rgb: 82, 212, 255;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #48bbe0;
    --ion-color-tertiary-tint: #63d8ff;

    /** success **/
    --ion-color-success: #92D35D;
    --ion-color-success-rgb: 146, 211, 93;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #80ba52;
    --ion-color-success-tint: #9dd76d;

    /** warning **/
    --ion-color-warning: #FCD125;
    --ion-color-warning-rgb: 252, 209, 37;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #deb821;
    --ion-color-warning-tint: #fcd63b;

    /** danger **/
    --ion-color-danger: #FF6142;
    --ion-color-danger-rgb: 255, 97, 66;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e0553a;
    --ion-color-danger-tint: #ff7155;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        --ion-color-primary: #122C66;
        --ion-color-primary-rgb: 121, 179, 138;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #1E407B;
        --ion-color-primary-tint: #122C66;

        --ion-color-secondary: #c1cdc4;
        --ion-color-secondary-rgb: 193, 205, 196;
        --ion-color-secondary-contrast: #000000;
        --ion-color-secondary-contrast-rgb: 0, 0, 0;
        --ion-color-secondary-shade: #aab4ac;
        --ion-color-secondary-tint: #c7d2ca;

        --ion-color-tertiary: #52d4ff;
        --ion-color-tertiary-rgb: 82, 212, 255;
        --ion-color-tertiary-contrast: #000000;
        --ion-color-tertiary-contrast-rgb: 0, 0, 0;
        --ion-color-tertiary-shade: #48bbe0;
        --ion-color-tertiary-tint: #63d8ff;

        --ion-color-success: #92D35D;
        --ion-color-success-rgb: 146, 211, 93;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #80ba52;
        --ion-color-success-tint: #9dd76d;

        --ion-color-warning: #FCD125;
        --ion-color-warning-rgb: 252, 209, 37;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #deb821;
        --ion-color-warning-tint: #fcd63b;

        --ion-color-danger: #FF6142;
        --ion-color-danger-rgb: 255, 97, 66;
        --ion-color-danger-contrast: #000000;
        --ion-color-danger-contrast-rgb: 0, 0, 0;
        --ion-color-danger-shade: #e0553a;
        --ion-color-danger-tint: #ff7155;

        --ion-color-dark: #222428;
        --ion-color-dark-rgb: 34, 36, 40;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #1e2023;
        --ion-color-dark-tint: #383a3e;

        --ion-color-medium: #92949c;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;

        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #f5f6f9;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-item-background: #000000;

        --ion-card-background: #1c1c1d;
    }

    .ios ion-modal {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }


    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222222;

        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: #1e1e1e;

        --ion-toolbar-background: #1f1f1f;

        --ion-tab-bar-background: #1f1f1f;

        --ion-card-background: #1e1e1e;
    }
}
