
ion-card.product { //single product flash
    margin: 0;
    cursor: pointer;
    margin-bottom: 5px;

    .thumb-containter {
        position: relative;
        overflow: hidden;

        ion-button {
            position: absolute;
            background: var(--ion-color-light);
            transition: .5s bottom;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -50px;
            width: max-content;
        }
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    &:hover {
        .thumb-containter {
            img {
                filter: blur(8px);
            }

            ion-button {
                bottom: 35%;
            }
        }
    }

    ion-card-content {
        padding: 0;
    }

    h2 {
        text-overflow: ellipsis;
        text-align: center;
        padding: 0 10px 0;
        font-family: var(--secondary-font-family);
        font-size: 14px;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        letter-spacing: 1px;
        color: var(--ion-color-dark-tint);
        margin: 0 0 14px;
        font-weight: 600;
    }

    .pricing {
        line-height: 1.3;
        font-family: var(--primary-font-family);
        padding: 0 0 10px;
        text-align: center;

        .regular {
            text-decoration: line-through;
        }

        .sale {
            font-size: 16px;
            font-weight: 700;
            margin-left: 5px;
        }
    }
}
