.home-slider{
    ion-col{
        height: 450px;
        > div{
            height: 100%;
        }
        .mini-slider{
            img{
                object-fit: cover;
                width: 100%;
            }
        }

        @media only screen and (max-width: 1441px) {
            height: 340px;
        }
        @media only screen and (max-width: 1025px) {
            height: 250px;
        }
    }
}
