header {
    .search-bar {
        --inner-padding-end: 5px;
    }

    .buttons {
        text-align: right;
        margin-top: 15px;
    }


    .brand {
        display: flex;
        align-items: center;
        color: var(--ion-color-dark);
        text-decoration: none;
        padding: 10px 0;
        max-width: 100%;

        img {
            height: 50px;
            max-width: 100%;
        }

        h1 {
            margin: 5px 0 0 0;
            font-family: var(--primary-font-family);
            font-size: 50px;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 1px;
        }
    }

    .desktop {
        ion-col {
            padding: 0;
        }
    }

    .mobile {
        display: none;

        @media only screen and (max-width: 576px) {
            display: block;
        }

        .brand {
            justify-content: center;
        }

        .search-bar {
            margin-top: 0;
        }
    }

    .tab {

        @media only screen and (max-width: 576px) {
            display: none;
        }

        @media only screen and (min-width: 769px) {
            display: none;
        }
    }
    .desktop {
        .search-bar {
            margin-top: 15px;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
}
